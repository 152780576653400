import '../scss/style.scss';


import Swiper from 'swiper';
import '../../node_modules/swiper/css/swiper.min.css'

import $ from 'jquery';
window.jQuery = $;
window.$ = $;


$(document).ready(function () {

  // HERO SLIDER INIT
  var heroInitialised = false;  
  var heroSwipper = new Swiper ('.heroSwipper', {
    init: false,
    autoplay: {
      disableOnInteraction: false
    },
    initialSlide: 0,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    speed: 1000, 
    pagination: {
      el: '.heroPagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<div class=" ' + className + ' "><span class="progress"></span></div>';
      },
    },
    on: {
      init: function() {

        var vdo = this.slides[heroSwipper.activeIndex];
        var vidTarget = vdo.querySelector("video");
        if(vidTarget) {
          vidTarget.play();
        }

        var slideIndx = heroSwipper.activeIndex;
        var durat = timesArray[slideIndx];
        $('.heroPagination .swiper-pagination-bullet-active').ready(function () {
          var pag = $(this).find('.swiper-pagination-bullet-active .progress');
          pag.animate({'width':'100%'}, durat);
          heroInitialised = true;
        });
      },

      slideChange: function() {
        var slideIndx = heroSwipper.activeIndex;
        var durat = timesArray[slideIndx];
        var pag = $('.heroPagination .swiper-pagination-bullet-active').find('.progress');
        if(heroInitialised != false) {
          $('.heroPagination .progress').stop();
          $('.heroPagination .progress').css('width', '0%');
        }
        pag.animate({'width':'100%'}, durat);

        var vdo = this.slides[heroSwipper.activeIndex];
        var vidTarget = vdo.querySelector("video");
        if(vidTarget) {
          vidTarget.pause();
          vidTarget.currentTime = 0;
          vidTarget.play();
        }
      },
    },
  });

  if($('.heroSwipper').length > 0) {
    heroSwipper.init();
  };

  var visibleOnNot = true;
  $(window).scroll(function(){ 
    var count = $(window).scrollTop();
    var height = $(window).height();
    var pag = $('.heroPagination .swiper-pagination-bullet-active').find('.progress');
    var vdo = heroSwipper.slides[heroSwipper.activeIndex];
    var durat = timesArray[heroSwipper.activeIndex];
    var vidTarget = vdo.querySelector("video");
    if(count > height && visibleOnNot === true) {
      visibleOnNot = false;
      pag.stop();
      pag.css('width', '0%');
      heroSwipper.autoplay.stop();
      if(vidTarget) { vidTarget.pause(); vidTarget.currentTime = 0; };
    } else if (count <= height && visibleOnNot === false) {
      if(vidTarget) { vidTarget.play(); }
      visibleOnNot = true;
      heroSwipper.slideReset();
      heroSwipper.autoplay.start();
      pag.animate({'width':'100%'}, durat);
    }
  });  

  // PROJECTS SLIDER INIT
  var projectsSwiper = new Swiper('.projectsSwipper', {
    // effect: 'coverflow',
    effect: 'slide',
    centeredSlides: true,
    slidesPerView: '1',
    // speed: 1000,
    speed: 1500,
    navigation: {
      nextEl: '.projectsControls-button-next',
      prevEl: '.projectsControls-button-prev',
    },
    breakpoints: {
      320: {
        spaceBetween: -15,
      },
      769: {
        // spaceBetween: 30,
        spaceBetween: -20,
      }
    },
    pagination: {
      el: '.projectsPagination',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        return '<li class=" ' + className + ' listMenu__item listMenu__item--light"><a><span>' + (projects[index]) + '</span></a></li>';
      },
    },
  });

  // SLOGAN SLIDER INIT
  var autopl = 7000;
  var sloganInitilised = false;
  var sloganSwiper = new Swiper('.sloganSlider', {
    init: false,
    effect: 'slide',
    centeredSlides: true,
    slidesPerView: '1',
    spaceBetween: 70,
    speed: 1000,
    watchSlidesProgress: true,
    loop: true,
    autoplay: {
      delay: autopl,
      disableOnInteraction: false
    },
    pagination: {
      el: '.sloganPagination',
      clickable: true,
      type: 'bullets',
      renderBullet: function (index, className) {
        return '<div class=" ' + className + ' "><span class="progress"></span></div>';
      },
    },
    on: {
      init: function() {
        var durat = autopl;
        $('.sloganSlider .swiper-pagination-bullet-active').ready(function () {
          var pag = $(this).find('.swiper-pagination-bullet-active .progress');
          pag.animate({'width':'100%'}, durat);
        });
      },

      slideChange: function() {
        var durat = autopl;
        var pag = $('.sloganSlider .swiper-pagination-bullet-active').find('.progress');
        $('.sloganSlider .progress').stop();
        $('.sloganSlider .progress').css('width', '0%');
        pag.animate({'width':'100%'}, durat);
      }
    },
  });

  $(window).scroll(function(){
    if($('.sloganSlider').isInViewport() && sloganInitilised != true) {
      sloganInitilised = true;
      sloganSwiper.init();
    }
  });

  // accordeon 
  var acc = document.getElementsByClassName("head");
  var i;
  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  };

  if ($(window).width() < 768) {
    $('.accordeon').find('.accordeon__block').each(function() {
      var text = $(this).find('.head__subtitle');
      var cont = $(this).find('.body__content');
      cont.prepend(text);
    });
  };

   // FORMOS INPUTU ANIMACIJOS
  $( "div.inputWrap" ).find('input').focus(function() {
    $( this ).parent().find('label').addClass('act');
    $( this ).parent().addClass('focused');
  });

  $( "form" ).focusout(function() {
    let inputas = $( this ).find('input');
    inputas.each(function() {
      if (!$(this).val()) {
        $(this).parent().find('label').removeClass('act');
        $( this ).parent().removeClass('focused');
      } 
    });
  });

  // schrink nav on scroll
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementById("header").classList.add("shrink");
    } else {
      document.getElementById("header").classList.remove("shrink");
    }
  };

  // video controls
  var videoStarted = false;
  $('.play__icon').on('click', function() {
    videoStarted = true;
    var video = $(this).parent().parent().parent().find('video');
    var overlay = $(this).parent().parent();
    overlay.addClass('hidden');
    video.get(0).play();
    videoEnd();
  });

  function videoEnd() {
    $('video').on('ended',function(){
      videoStarted = false;
      $('.video__overlay').removeClass('hidden');
    });
  };

  // video play pause
  $(window).scroll(function(){
    if ($('.videoBlock video').length > 0) {
      $('video').each(function(i, prod) {
        if(!$(this).isInViewport() && videoStarted) {
          $(prod)[0].pause();
        } else if ($(this).isInViewport() && videoStarted) {
          $(prod)[0].play();
        }
      });
    };
  });

  // cookie 
  var closeBtn = document.getElementById('cookieClose');
  if(closeBtn) {
    closeBtn.addEventListener('click', function(){
      this.closest('.cookie').classList.add('closed');
      setCookie('acceptCookie', 'true', 365);
    });
  };

  function setCookie(cname , cvalue, exdays )  {
    let expires = '';
    if (exdays > 0) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      expires = `expires=${d.toUTCString()}`;
    } else {
      expires = '01 Jan 1970 00:00:01 GMT';
    }
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  };

  // scrooler button
  $('.scrollDown__icon').on('click', function(){
    var target = $('.scrollDown').prev().height();
    $("html, body").animate({ scrollTop: target }, "slow");
      return false;
  });
  

  $('.partnersLogo__img').on('click', function() {
    var name = $(this).data('target');
    var target = $('#' + name);
    $("html, body").animate({ 
        scrollTop: target.offset().top 
    }, 1500);
      return false;
  });

  // tables wrap 
  if ($(window).width() < 768) {
    $('.textPage').find('table').each(function() {
      $(this).before('<div class="scrollIcon"><div class="scrollIcon__anim"></div>Slinkti į šoną</div>');
      $(this).wrap('<div class="tableOverflowWrap">');
    });
  };

  // mobile menu
  $('.mMenuToggler').on('click', function() {
    $('.mobileMenu').toggleClass('open');
    $('html').toggleClass('open');
    $('.mMenuToggler').toggleClass('open');
  });

  // animation for first image from the list 
  if ($('.imgAnimate__onLoad').length > 0) {
    $('.imgAnimate__onLoad').each(function(){
      setTimeout(function(){ 
        $('.imgAnimate__onLoad').addClass('found'); 
      }, 1500);
    });
  };
});
// document ready end


// ANIMATIONS ON SCROLL
var lastScrollTop = 0;
$(window).scroll(function(){

  // img fade-in
  var st = $(this).scrollTop();
  if ($('.imgAnimate:not(.found):not(.imgAnimate__onLoad)').length > 0) {
    $('.imgAnimate:not(.found):not(.imgAnimate__onLoad)').each(function(i, prod){
      var prodTop = $(prod).offset().top + (parseFloat($(prod).css('height'))/3);
      var mark = window.pageYOffset + (window.innerHeight*8/9);

      if (mark > prodTop) {
        $(prod).addClass('found');
      }
    });
  }

  // text fade-in
  if ($('.textAnimate:not(.found)').length > 0) {
    $('.textAnimate:not(.found)').each(function(i, prod){
      var prodTop = $(prod).offset().top + (parseFloat($(prod).css('height'))/3);
      var mark = window.pageYOffset + (window.innerHeight*8/9);

      if (mark > prodTop) {
        $(prod).addClass('found');
      }
    });
  }

  // bounce effect
  if ($('.hasTail').length > 0) {
    $('.hasTail').each(function(i, prod){
      var prodTop = $(prod).offset().top + (parseFloat($(prod).css('height'))/3);
      var mark = window.pageYOffset + (window.innerHeight*8/9);

      if (mark > prodTop && st > lastScrollTop) {
        if($(prod).isInViewport() && !$(prod).hasClass('hasTail__up')) {
          $(prod).addClass('hasTail__down');
        }
      } else {
        if($(prod).isInViewport() && !$(prod).hasClass('hasTail__down')) {
          $(prod).addClass('hasTail__up');
        }
      }
    });
  }

  clearTimeout($.data(this, 'scrollTimer'));
  $.data(this, 'scrollTimer', setTimeout(function() {
      $('.hasTail').removeClass('hasTail__up');
      $('.hasTail').removeClass('hasTail__down');
  }, 250));

  // service list icons animations
  if ($('.servicesList__item').length > 0) {
    var delay = 0;
    $('.servicesList__item').each(function(i, prod){
      var prodTop = $(prod).offset().top + (parseFloat($(prod).css('height'))/3);
      var mark = window.pageYOffset + (window.innerHeight*8/9);

      if (mark > prodTop && st > lastScrollTop) {
        setTimeout(function(){ 
          $(prod).delay(delay).addClass('found'); 
        }, delay);
        delay += 500;
      }
    });
  }

  // project page, icons animation
  if ($('.techTable__icon').length > 0) {
    var delay = 0;
    $('.techTable__icon').each(function(i, prod){
      var prodTop = $(prod).offset().top + (parseFloat($(prod).css('height'))/3);
      var mark = window.pageYOffset + (window.innerHeight*8/9);

      if (mark > prodTop && st > lastScrollTop) {
        setTimeout(function(){ 
          $(prod).delay(delay).addClass('found'); 
        }, delay);
        delay += 300;
      }
    });
  }

  // instagram images animation
  if ($('.insta__imgWrap').length > 0) {
    var delay = 0;
    $('.insta__imgWrap').each(function(i, prod){
      var prodTop = $(prod).offset().top + (parseFloat($(prod).css('height'))/3);
      var mark = window.pageYOffset + (window.innerHeight*8/9);

      if (mark > prodTop && st > lastScrollTop) {
        setTimeout(function(){ 
          $(prod).delay(delay).addClass('found'); 
        }, delay);
        delay += 300;
      }
    });
  }

  // accordeon blocks animation
  if ($('.accordeon:not(.found)').length > 0) {
    $('.accordeon:not(.found)').each(function(i, prod){
      var prodTop = $(prod).offset().top + (parseFloat($(prod).css('height'))/3);
      var mark = window.pageYOffset + (window.innerHeight*8/9);

      if (mark > prodTop) {
        $(prod).addClass('found');
      }
    });
  }

  // inner page hero img overlay darken on scroll
  lastScrollTop = st;
  if('.innerHero__overlay') {
    $('.innerHero__overlay').css({
      opacity: function() {
        var elementHeight = $(this).height();
        return 1 - (elementHeight - st) / elementHeight;
      }
    });
  }
});
// scroll functions end

// if is in viewport
$.fn.isInViewport = function() {
  if(this.length > 0) {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
  }
};


// newsletter validation & AJAX
$('.submit').on('click', function(e) {
  e.preventDefault();
  let errorCounter = 0,
      validOrNot = false,
      emailVal = $('[name="email"]').val(),
      regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if(emailVal == "" || !regex.test(emailVal)) {
    $('[name="email"]').parent().addClass('hasError');
    errorCounter ++;
  } else {
      $('[name="email"]').parent().removeClass('hasError');
  }  

  $('form').find('input[type=checkbox]').each(function(){
      if ($(this).is(':checked')) {
          $('.checkbox').removeClass('hasError');
      } else {
          $('.checkbox').addClass('hasError');
          errorCounter ++;
      }
  });
  
  if (errorCounter > 0) {
      validOrNot = false;
  } else {
      validOrNot = true;
  }

  if(validOrNot){
      $('.submit').addClass('disabled');
      $.ajax({
          type: 'POST',
          url: $('[data-contact-form]').attr('data-action'),
          data: {email: $('[name="email"]').val(), acceptance: $('[name="acceptance"]').val()},
          success: function(response) { 
              var resp = JSON.parse(response);
              console.log(resp.msg);
              if(resp.msg === "success") {
                $('.submit').addClass('success');
                $('.submit').removeClass('disabled');
                window.setTimeout(function(){
                  $(".submit").removeClass('success');
                  formReset();
                }, 4000);
              };

          },
          error: function(response) {
              console.log('Error', response);
              $('.submit').removeClass('disabled');
          }
      });
  }
});

// form reset
function formReset() {
  $('input[type=checkbox]').each(function(){
    $(this).prop('checked', false);
    $('[name=email]').val('');
  });
}


// google maps init
$('.contacts__mapBlock').ready(function() {
  var mapsOptions = {
    zoom: 15,
    panControl: false,
    rotateControl: false,
    scaleControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    scrollwheel: false,
    zoomControl: false,
    styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
  };

  function initMain(mapDiv) {
    var map = {
      options: mapsOptions,
      center: new google.maps.LatLng(mapDiv.data('lat'), mapDiv.data('lng')),
      domObj: mapDiv.get(0)
    }

    map.map = new google.maps.Map(map.domObj, map.options);
    map.map.setCenter(map.center);

    map.marker = new google.maps.Marker({
      position: map.center,
      map: map.map,
      animation: google.maps.Animation.DROP,
      icon: {
        url: './assets/images/pin.svg',
        scaledSize: new google.maps.Size(27, 39),
        origin: new google.maps.Point(0,0)
      },
    });
  };

  if ($('#map').length > 0) {
    google.maps.event.addDomListener(window, 'load', initMain($('#map')));
  }
});
